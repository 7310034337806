<template>
  <div>
    <ReportCenterTitle v-if="!inside" title="certification" :count="count" />
    <h6 class="mt-2" v-else>
      <router-link to="/certification"
        >{{ $t("certification") }} ({{ count }})</router-link
      >
    </h6>
    <Table
      :hideHeader="inside"
      :count="count"
      :headers="headers"
      no-search
      :loading="isLoading"
      :items="items"
      no-card
    >
      <template #certificateDate="{ item }">
        {{ formatter(item.certificateDate) }}
      </template>
    </Table>
    <div v-if="!inside" class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
  </div>
</template>

<script>
import ReportCenterTitle from "../Title.vue";
import Table from "../../Tables/index.vue";
import { formatter } from "gurhan/helpers/Date";
import { Currency } from "gurhan/helpers/Currency";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";

export default {
  components: {
    BoostrapPagination,
    ReportCenterTitle,
    Table,
  },
  props: {
    inside: {
      default: false,
      type: Boolean,
    },
    limit: {
      default: 10,
    },
  },
  data() {
    return {
      items: [],
      isLoading: true,
      count: 0,
      page: 1,
      headers: [
        {
          title: this.$t("user"),
          value: "userFullName",
          col: 12,
          md: 4,
          lg: 4,
          xl: 4,
        },
        {
          title: this.$t("productName"),
          value: "productName",
          col: 12,
          md: 4,
          lg: 4,
          xl: 4,
        },
        {
          title: this.$t("certificateDate"),
          value: "certificateDate",
          col: 12,
          md: 4,
          lg: 4,
          xl: 4,
        },
      ],
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.count / 10);
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    formatter,
    Currency,
    INSIDE_ORDERS() {
      this.headers = [
        {
          title: this.$t("user"),
          value: "user",
          col: 12,
          md: 12,
          lg: 12,
          xl: 12,
        },
        {
          title: this.$t("productName"),
          value: "product",
          col: 12,
          md: 9,
          lg: 9,
          xl: 9,
        },
        {
          title: this.$t("certificateDate"),
          value: "certificateDate",
          col: 12,
          md: 3,
          lg: 3,
          xl: 3,
        },
      ];
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { page } });
      this.GET_ITEMS();
    },
    async GET_ITEMS() {
      this.isLoading = true;
      const response = await this.$api.get(
        `Statistic/GetCertificateDetails?page=${this.page}&limit=${this.limit}`
      );
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    const q = this.$route.query;
    if (!isNaN(Number(q.page))) this.page = Number(q.page);
    this.GET_ITEMS();
    if (this.inside) this.INSIDE_ORDERS();
  },
};
</script>

<style></style>
